@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap');

@layer base {
	body {
		@apply bg-gray-200;
		font-family: 'Poppins', sans-serif;
		@apply max-w-md mx-auto min-h-screen;
	}
}

@layer components {
	.modalOverlay {
		@apply bg-black min-h-full w-screen bg-opacity-50 fixed left-0 top-0 z-50 flex justify-center items-center;
	}
	.modalCover {
		@apply w-full max-w-md h-fit m-5 bg-white rounded-md;
	}
	.submitBtn {
		@apply border text-white bg-black items-center justify-center flex mt-3 p-3 w-36 rounded-lg mx-auto;
	}
}
